#edit_about{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

#about_image_container{
    border: 4px solid #7F7F7F;
    margin: 20px 20px;
    border-radius: 20px;
    width: 95%;
}

#about_image_container img{
    width: 100%;
}

#add_about_image{
    background-color: #7F7F7F;
    width: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
}

#add_about_image p{
    color: #FFFFFF;
    font-weight: 700;
    margin: 0px;
}

#about_image_input{
    margin: 20px 0px;
}

#about_button{
    border: none;
    border-radius: 20px;
    background-color: #FFFFFF;
    color: #7F7F7F;
    font-weight: 800;
    padding: 10px;
}

#add_member{
    display: grid;
    grid-template-columns: 3fr 1fr;
}

#output{
    display: flex;
    flex-direction: column;
}

#up{
    display: flex;
    justify-content: space-around;
    margin: 40px 0px 0px 0px;
}

#left{
    width: 45%;
}

#left img{
    width: 100%;
    height: auto;
}

#right{
    width: 45%;
    text-align: left;
}

#name{
    margin: 0px;
    font-size: 22px;
    font-weight: 600;
}

#designation{
    margin: 0px;
    font-size: 18px;
    font-weight: 600;
}

#mobile{
    margin: 0px;
    font-size: 18px;
    font-weight: 600;
}

#email{
    margin: 0px;
    font-size: 18px;
    font-weight: 600;
}

#massage{
    margin: 0px;
    font-size: 16px;
    font-weight: 600;
}

#down{
    text-align: left;
    padding: 30px;
}

#details_title{
    margin: 0px;
    font-size: 18px;
    font-weight: 700;
}

#down img{
    width: 100%;
}

#input_form{
    border: none;
}

.input_section{
    border: 1px solid #7f7f7f;
    background-color: #7f7f7f;
    margin: 10px 0px;
    padding: 10px 0px;
    border-radius: 10px;
}

.input_title{
    margin: 10px 0px;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 18px;
}

.designation_option{
    text-align: left;
    margin: 0px 0px 0px 20px;
}

.designation_option label{
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 700;
}

#button{
    border: none;
    background-color: #054ADA;
    color: #FFFFFF;
    padding: 10px;
    font-size: 18px;
    border-radius: 10px;
    margin: 0px 0px 30px 0px;
}