#picture{
    border: 4px solid #7F7F7F;
    margin: 30px;
    display: grid;
    grid-template-columns: 8fr 2fr;
}

#image_container{
    margin: 10px;
}

#image_container img{
    max-width: 100%;
    max-height: 300px;
}

#button_container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#delete_button{
    border: none;
    background-color: #FF0000;
    padding: 10px;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 700;
    border-radius: 15px;
}

#button_text{
    color: #7F7F7F;
    font-weight: 600;
}