#add_member{
    display: grid;
    grid-template-columns: 3fr 1fr;
}

#output{
    display: flex;
    flex-direction: column;
}

#up{
    display: flex;
    justify-content: space-around;
}

#left{
    width: 45%;
}

#left img{
    width: 100%;
    height: auto;
}

#right{
    width: 45%;
    text-align: left;
}

#name{
    margin: 0px;
    font-size: 22px;
    font-weight: 600;
}

#designation{
    margin: 0px;
    font-size: 18px;
    font-weight: 600;
}

#mobile{
    margin: 0px;
    font-size: 18px;
    font-weight: 600;
}

#email{
    margin: 0px;
    font-size: 18px;
    font-weight: 600;
}

#massage{
    margin: 0px;
    font-size: 16px;
    font-weight: 600;
}

#down{
    text-align: left;
    padding: 30px;
}

#details_title{
    margin: 0px;
    font-size: 18px;
    font-weight: 700;
}

#down img{
    width: 100%;
}

#input{
    border: none;
}

#delete_button{
    border: none;
    background-color: #FF0000;
    padding: 20px;
    border-radius: 30px;
    color: #FFFFFF;
    font-size: 18px;
    margin: 50px 0px;
}