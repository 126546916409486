#outlet_container{
    margin: 115px 0px 0px 0px;
}

#footer_container{
    border: none;
}

#header_container{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
}

@media only screen and (max-width: 950px) and (min-width: 650px) {
    #outlet_container{
        margin: 215px 0px 0px 0px;
    }
}

@media only screen and (max-width: 650px) and (min-width: 595px) {
    #outlet_container{
        margin: 230px 0px 0px 0px;
    }
}

@media only screen and (max-width: 595px) {
    #outlet_container{
        margin: 85px 0px 0px 0px;
    }
}