#member_details{
    margin: 150px 0px 20px 0px;
    display: grid;
    grid-template-columns: 1fr 8fr 1fr;
}

#middle{
    display: flex;
    flex-direction: column;
}

#up{
    display: flex;
    justify-content: space-around;
}

#left{
    width: 45%;
}

#left img{
    width: 50%;
    height: auto;
}

#right{
    width: 45%;
    text-align: left;
}

#name{
    margin: 10px 0px;
    font-size: 28px;
    font-weight: 600;
    color: #054ADA;
    font-weight: 700;
}

#designation{
    margin: 0px;
    font-size: 24px;
    font-weight: 600;
}

#contact_container{
    margin: 20px 0px;
    display: flex;
    align-items: center;
}

#icon{
    width: 46px;
    height: 46px;
    background-color: #054ADA;
    color: #FFFFFF;
    padding: 10px;
    border-radius: 23px;
}

#mobile{
    border-radius: 25px;
    color: #054ADA;
    margin: 0px;
    width: 170px;
    padding: 10px;
    font-size: 18px;
    font-weight: 600;
}

#email_container{
    display: flex;
    align-items: center;
}

#email_icon{
    width: 46px;
    height: 46px;
    background-color: #FF8800;
    color: #FFFFFF;
    padding: 10px;
    border-radius: 23px;
}

#email{
    margin: 0px 0px 0px 10px;
    font-size: 18px;
    font-weight: 600;
    color: #FF8800;
}

#massage{
    margin: 20px 0px;
    font-size: 16px;
    font-weight: 600;
}

#down{
    text-align: left;
    padding: 30px 0px;
}

#details_title{
    margin: 0px;
    font-size: 18px;
    font-weight: 700;
}

#down img{
    width: 100%;
}

@media only screen and (max-width: 1280px){
    #up{
        flex-direction: column;
    }
    #left{
        width: 100%;
    }
    #right{
        width: 100%;
    }
}

@media only screen and (max-width: 595px){
}