#product_viewer{
    border: none;
}

#products_conatiner{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 30px;
    padding:  30px 80px;
}

#button_container{
    display: flex;
    align-items: center;
    justify-content: center;
}

.selected{
    border: none;
    border-radius: 10px;
    color: #FFFFFF;
    background-color: #054ADA;
    padding: 10px;
    margin: 20px;
    font-size: 16px;
    cursor: pointer;
}

.unselected{
    border: none;
    border-radius: 10px;
    color: #054ADA;
    background-color: #FFFFFF;
    padding: 10px;
    margin: 20px;
    font-size: 16px;
    cursor: pointer;
}

@media only screen and (max-width: 1150px)and (min-width: 800px){
    #products_conatiner{
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 800px){
    #products_conatiner{
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 595px){
    #products_conatiner{
        padding:  10px;
        grid-gap: 15px;
    }
}