#add_picture{
    border: none;
}

#input_section{
    border: 1px solid #7F7F7F;
    background-color: #7F7F7F;
    padding: 10px;
    display: inline-flex;
    flex-direction: column;
    margin: 20px;
}

#input_title{
    margin: 10px 0px;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 700;
}

#input_button{
    border: none;
    border-radius: 15px;
    margin: 10px;
    color: #7F7F7F;
    padding: 15px;
    font-size: 18px;
    font-weight: 600;
}

#image_container{
    border: 6px solid #7F7F7F;
    margin: 30px;
}

#image_container img{
    width: 100%;
}