#container{
    display: flex;
}

#info_container{
    width: 75%;
}

#info_up{
    padding: 20px;
    display: flex;
}

#image_container{
    width: 50%;
}

#image_container img{
    width: 100%;
    border: none;
}

#basic_info{
    width: 50%; 
}

#product_name{
    color: #000000;
    font-size: 28px;
    margin: 10px 30px;
}

#product_price{
    background-color: #054ADA;
    color: #FFFFFF;
    font-size: 18px;
    width: 250px;
    height: 28px;
    margin: 10px 30px;
    border-radius: 14px;
}

#categori_title{
    text-align: left;
    margin: 20px 30px;
    font-size: 22px;
}

.categori{
    text-align: left;
    margin: 20px 30px;
}

#seo_description_title{
    text-align: left;
    margin: 20px 30px;
    font-size: 22px;
}

#seo_description{
    text-align: left;
    margin: 20px 30px;
}

#seo_keyword_title{
    text-align: left;
    margin: 20px 30px;
    font-size: 22px;
}

#seo_keyword{
    text-align: left;
    margin: 20px 30px;
}

#info_down{
    border: 2px solid blue;
    border: none;
    text-align: left;
    margin: 30px;
}

#info_down img{
    width: 100%;
}

#others{
    width: 25%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

#delete_button{
    border: none;
    background-color: #FF0000;
    padding: 20px;
    border-radius: 30px;
    color: #FFFFFF;
    font-size: 18px;
    margin: 50px 0px;
}

#product_model{
    text-align: left;
    margin: 20px 30px;
    font-size: 22px;
}