#member{
    border: 2px solid #054ADA;
    border-radius: 10px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

#image{
    width:  180px;
    height: 180px;
    margin: 0px;
}

#name{
    border: none;
    padding: 0px;
    margin: 10px 15px;
    font-size: 20px;
    text-align: left;
    font-weight: 800;
    color: #054ADA;
}

#designation{
    border: 1px solid #054ADA;
    border-radius: 13px;
    background-color: #054ADA;
    margin: 0px 0px 60px 0px;
    width: 300px;
    height: 25px;
    color: #FFFFFF;
    font-size: 16px;
}

#link{
    position: absolute;
    background-color: #FF8800;
    width: 100%;
    bottom: 0;
    padding: 10px 0px;
    text-decoration: none;
    color: #FFFFFF;
    font-size: 16px;
    border-radius: 0px 0px 10px 10px;
}


@media only screen and (max-width: 595px){
    #image{
        margin: 5px;
        width: 120px;
        height: 120px;
    }
    #name{
        font-size: 12px;
        margin: 5px;
    }
    #designation{
        width: auto;
        height: auto;
        font-size: 10px;
        padding: 5px 10px;
        margin: 0px 0px 35px 0px;
    }
    #link{
        padding: 5px 0px;
        font-size: 12px;
    }
}