#container{
    display: grid;
    grid-template-columns: 1fr 6fr 1fr;
}

#leftt_side{
    border: none;
}

#info_container{
    border: none;
}

#info_up{
    padding: 20px;
    display: flex;
}

#image_container{
    width: 50%;
}

#image_container img{
    width: 100%;
    border: none;
}

#basic_info{
    width: 50%;
}

#product_name{
    color: #000000;
    font-size: 28px;
    margin: 10px 30px;
    text-align: left;
}

#product_price{
    background-color: #054ADA;
    color: #FFFFFF;
    font-size: 18px;
    width: 250px;
    height: 29px;
    margin: 50px 30px 10px 30px;
    border-radius: 14px;
}

#product_model{
    text-align: left;
    margin: 10px 30px;
    font-size: 18px;
}

#categori_container{
    margin: 0px 30px;
}

#categori_title{
    text-align: left;
    margin: 10px 0px;
    font-size: 20px;
    font-weight: 700;
    display: inline;
}

.categori{
    text-align: left;
    margin: 10px 5px;
    display: inline;
}

#product_description{
    text-align: left;
    margin: 20px 30px;
    font-size: 18px;
}

#seo_info{
    text-align: left;
}

.hr_line{
    border: 2px solid #000000;
    color: #000000;
    margin: 10px 30px;
}

.seo_paragraph{
    margin:  10px 0px;
    padding: 0px 0px;
}

#info_down{
    text-align: left;
    margin: 30px;
}

#description_title{
    font-weight: 700;
    font-size: 19px;
}

#info_down img{
    width: 100%;
}

#right_side{
    border: none;
}

#contact_box{
    text-align: center;
    border: 2px solid #054ADA;
    background-color: #054ADA;
    border-radius: 15px;
    margin: 10px 30px;
    width: 250px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#contact_box p{
    margin: 0px 0px 0px 10px;
    color: #FFFFFF;
    font-size: 18px;
}

#optional_message{
    text-align: left;
    margin: 0px 30px;
    font-weight: 500;
    font-size: 18px;
}

@media only screen and (max-width: 1280px){
    #info_up{
        flex-direction: column;
    }
    #image_container{
        width: 100%;
    }
    #basic_info{
        width: 100%;
    }
}

@media only screen and (max-width: 595px){
}