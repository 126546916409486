#contact_page{
    border: none;
    display: flex;
    flex-direction: column;
}

#contact{
    display: flex;
    justify-content: space-around;
    margin: 0px 70px;
}

#contact_form{
    width: 45%;
}

#form_title{
    margin: 24px 0px;
    text-align: left;
    font-weight: 700;
    font-size: 20px;
    color: #054ADA;
}

.form_section{
    margin: 15px 0px;
    display: flex;
    justify-content: space-between;
}

#first_name{
    width: 45%;
    height: 35px;
}

#last_name{
    width: 45%;
    height: 35px;
}

#mobile{
    width: 45%;
    height: 35px;
}

#email{
    width: 45%;
    height: 35px;
}

#message{
    width: 100%;
    height: 170px;
}

#button_container{
    flex-direction: row-reverse;
}

#button{
    text-align: right;
    background-color: #054ADA;
    border: none;
    padding: 10px 20px;
    border-radius: 15px;
    color: #FFFFFF;
    font-size: 18px;
}

#contact_info{
    width: 45%;
}

#contact_title{
    margin: 24px 0px;
    text-align: left;
    font-weight: 700;
    font-size: 20px;
    color: #054ADA;
}

.contact_section{
    margin: 30px 0px;
    display: flex;
    align-items: center;
}

.icon_container{
    width: 50px;
    height: 50px;
    background-color: #054ADA;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon{
    width: 35px;
    height: 35px;
    color: #FFFFFF;
}

.contact_text{
    margin: 0px 0px 0px 20px;
    text-align: left;
}

.contact_type{
    margin: 0px;
    font-size: 18px;
    font-weight: 600;
    color: #054ADA;
}

.contact_description{
    margin: 0px;
}

@media only screen and (max-width: 1000px){
    #contact{
        flex-direction: column-reverse;
    }
    #contact_form{
        width: 100%;
    }
    #contact_info{
        width: 100%;
    }
}

@media only screen and (max-width: 650px){
    .form_section{
        flex-direction: column;
    }
    #first_name{
        width: 100%;
    }
    #last_name{
        width: 100%;
    }
    #mobile{
        width: 100%;
    }
    #email{
        width: 100%;
    }
}