#login{ 
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 500px 
}

#button{
    border: 6px solid #FF8800;
    border-radius: 24px;
    font-size: 24px;
    color: #FF8800;
    font-weight: 700;
    background-color: #054ADA;
    padding: 18px;
}