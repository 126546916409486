#shop_header{
    border: 4px solid #054ADA;
}

#up{
    background-color: #054ADA;
    padding: 8px 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#up_left{
    display: flex;
    align-items: center;
}

.contact_container{
    display: flex;
    align-items: center;
    margin: 0px 0px 0px 30px;
}

.contact_container p{
    font-size: 16px;
    margin: 0px 0px 0px 10px;
    color: #FFFFFF;
}

.icon{
    font-size: large;
}

#up_right{
    display: flex;
    align-items: center;
}

.social_link{
    margin: 0px 10px;
}

#down{
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#link_container{
    display: flex;
    margin: 0px 0px 0px 80px;
}

.activeClassName{
    background-color: #FFFFFF;
    color: #054ADA;
    padding: 24px 20px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
}

.activeClassName:hover{
    background-color: #FFFFFF;
    color: #054ADA;
}

.rout_link p{
    margin: 0;
    font-weight: 600;
}

.link_icon{
    width: 22px;
}

.hrl{
    border: 1px solid #054ADA;
}

#logo_container{
    border: none;
    width: 325px;
    margin: 0px 20px 0px 0px;
}

#logo_container img{
    width: 100%;
}

#product_link{
    position: relative;
}

#category_container{
    border: 2px solid #054ADA;
    width: 320%;
    position: absolute;
    left: 0px;
    top: 72px;
}

#category_container_close_button{
    display: none;
}

.category_link{
    border: none;
    margin: 0px;
    text-decoration: none;
}

.category{
    margin: 0px;
    text-align: left;
    background: #FFFFFF;
    color: #054ADA;
    padding: 5px;
    font-size: 17px;
    font-weight: 600;
}

.category:hover{
    background-color: #054ADA;
    color: #FFFFFF;
}

@media only screen and (max-width: 950px) and (min-width: 650px) {
    #up{
        flex-direction: column-reverse;
    }
    #down{
        flex-direction: column-reverse;
        align-items: left;
    }
    #link_container{
        margin: 0px;
    }
}

@media only screen and (max-width: 650px) and (min-width: 595px) {
    #up{
        flex-direction: column-reverse;
    }
    #up_left{
        flex-direction: column;
    }
    #down{
        flex-direction: column-reverse;
        align-items: left;
    }
    #link_container{
        margin: 0px;
    }
}

@media only screen and (max-width: 595px) {
    #up{
        display: none;
    }
    #down{
        flex-direction: column-reverse;
        align-items: left;
    }
    #link_container{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0px;
    }
    .link_icon{
        width: 16px;
    }
    .hrl{
        border: 1px solid #054ADA;
        height: 25px;
        margin: 0px 5px;
    }
    #category_container{
        left: -60px;
        top: 42px;
    }
    #category_container_close_button{
        font-size: 16px;
        display: block;
        background-color: #FFFFFF;
        color: #FF0000;
        text-align: right;
        margin: 0px;
        padding: 10px 30px 0px 0px;
    }
    .activeClassName{
        padding: 10px;
        font-size: 10px;
    }
    #logo_container{
        width: 200px;
    }
}