#about{
    border: none;
}

#title_up{
    font-size: 28px;
    font-weight: 700;
    margin: 160px 40px 40px 60px;
    color: #FF8800;
    text-align: left;
}

#text_up{
    font-size: 18px;
    font-weight: 600;
    text-align: left;
    margin: 20px 60px;
}

#title_down{
    font-size: 28px;
    font-weight: 700;
    margin: 40px 60px;
    text-align: left;
    color: #054ADA;
}

#text_down{
    margin: 40px 60px;
    font-size: 18px;
    font-weight: 600;
    text-align: left;
}

#about_image_container{
    margin: 0px 60px;
}

#about_image_container img{
    width: 100%;
}

#members_container{
    margin: 40px 60px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 40px;
}

@media only screen and (max-width: 1280px) and (min-width: 595px) {
    #members_container{
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 595px){
    #title_up{
        margin: 10px;
        font-size: 20px;
    }
    #about_image_container{
        margin: 0px 10px;
    }
    #title_down{
        font-size: 20px;
        margin: 30px 10px 10px 10px;
    }
    #members_container{
        grid-template-columns: 1fr 1fr;
        margin: 10px 10px;
        padding: 10px;
        grid-gap: 15px;
    }
}