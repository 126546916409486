#container{
    border: 2px solid #7F7F7F;
    margin: 20px;
}

#image_container{
    width: 100px;
}

#up{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

#link{
    border-radius: 10px;
    background-color: #7F7F7F;
    color: #FFFFFF;
    text-decoration: none;
    padding: 10px;
}

#image_container img{
    width: 100%;
}

.text{
    color: #7F7F7F;
    font-weight: 700;
    margin: 20px;
}