#footer{
    width: 100%;
    background-color: #FF8800;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.item{
    margin: 20px 50px;
    text-align: left;
}

.logo{
    margin: 0px 0px 0px 10px;
    height: 35px;
}

.logo img{
    height: 100%;
    width: auto;
}

.item_content_container{
    display: flex;
}

.link_icon{
    width: 22px;
    color: #FFFFFF;
}

.item_content{
    color: #FFFFFF;
    display: block;
    text-decoration: none;
    margin: 10px 0px 10px 10px;
}

.title{
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 700;
    margin: 0px 0px 0px 3px;
}

@media only screen and (max-width: 1080px) and (min-width: 860px){
    #footer{
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 860px){
    #footer{
        grid-template-columns: 1fr;
    }
}