#header{
    border: 2px solid #7F7F7F;
    background-color: #7F7F7F;
}

#title{
    margin: 30px 0px 0px 0px;
    color: #FFFFFF;
    font-size: 36px;
    font-weight: 700;
}

#link_container{
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.link{
    margin: 30px 60px;
    text-decoration: none;
    padding: 10px;
    background-color: #FFFFFF;
    color: #7F7F7F;
    font-weight: 700;
    font-size: 20px;
    border-radius: 15px;
}