#container{
    display: flex;
}

#info_container{
    width: 75%;
}

#info_up{
    padding: 20px;
    display: flex;
}

#image_container{
    width: 50%;
}

#image_container img{
    width: 100%;
    border: none;
}

#basic_info{
    width: 50%; 
}

#product_name{
    text-align: left;
    color: #000000;
    font-size: 28px;
    margin: 10px 30px;
}

#product_price{
    background-color: #054ADA;
    color: #FFFFFF;
    font-size: 18px;
    width: 250px;
    height: 28px;
    margin: 10px 30px;
    border-radius: 14px;
}

#categori_title{
    text-align: left;
    margin: 20px 30px;
    font-size: 22px;
}

#product_category_container{
    text-align: left;
}

.categori{
    text-align: left;
    margin: 20px 30px;
}

#optional_message{
    text-align: left;
    padding: 0px 10px;
    color: #FF0000;
}

#seo_info{
    text-align: left;
}

.hr_line{
    border: 2px solid #000000;
    color: #000000;
    margin: 0px 30px;
}

.seo_title{
    margin: 0px;
    font-weight: 700;
    padding: 10px 30px;
}

.seo_paragraph{
    margin:  10px 0px;
    padding: 0px 30px;
}

#info_down{
    border: none;
    text-align: left;
    margin: 30px;
}

#description_title{
    font-weight: 700;
    font-size: 19px;
}

#info_down img{
    width: 100%;

}

#others{
    width: 25%;
}

.input_label{
    border: 3px solid #7F7F7F;
    background-color: #7F7F7F;
    margin: 30px 0px;
    padding: 0px 0px 10px 0px;
    border-radius: 10px;
}

.input_title{
    margin: 10px 0px;
    color: #FFFFFF;
}

#add_button{
    font-size: 18px;
    color: #FFFFFF;
    background-color: #054ADA;
    border: none;
    border-radius: 10px;
    padding: 10px 20px;
    margin: 0px 0px 30px 0px;
}